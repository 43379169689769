/*! Place your custom styles here */
.pricing-1 h3{
    font-size: 1.5em;
}
.pricing-1 .h2{
    font-size:2em;
}

.pricing-1 ul{
    margin-bottom: 2em;
}

.pricing-1 .btn{
    margin-bottom: 2em;
}

.pricing-coding .h1{
    font-size: 2.8em;
}

.icon-small{
    font-size: 1.3em;
}

.logo{
    min-height: 35px;
}

#front-blog .blog-title-link, #front-blog .blog-title-link:hover {
    color: rgb(37, 37, 37);
    font-weight: 400;
}

#front-blog-detail article ol li:not(:last-child) {
	margin-bottom: 0;
}
#front-blog-detail article ul {
    list-style: disc;
}
#front-blog-detail article li {
    list-style: disc inside;
}
#front-blog .thumb {
    max-width: 200px;
    max-height: 150px;
}

/* COMMENTS */

#comments .media-list .media .media-left {
	margin-right: 1rem;
    float: left;
    min-width: 64px;
}
#comments .media-list .media .media-date {
    font-size: 0.9em;
}
#comments h3.my1 {
    margin-top: 0.78em;
    margin-bottom: 1em;
}
#comments h3.my2 {
    margin-top: 1.78em;
    margin-bottom: 0;
}
#comments h5.media-heading {
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1em;
}
#front-blog-detail #comments form.form {
    margin-top: 0;
}

/* REFERENCE */
#reference-detail .ref_images img {
	max-height: 150px;
	float: left;
	margin-right: 10px;
}

/* BLOG POSTS */
#front-blog .private-border, #front-blog-detail .private-border {
    border:2px solid #f61e1e
}
#front-blog-detail .private-border {
    color: #f61e1e
}
#front-blog-detail a.important_button {
    display: table;
    padding: 15px 10px;
    background-color: #4a90e2;
    color: #fff;
    width: 100%;
    text-decoration: none;
}
#front-blog-detail a.important_button span.text {
    float: left;
    text-decoration: underline;
}
#front-blog-detail a.important_button span.nazov {
    margin-left: 8px;
    text-decoration: none;
}
#front-blog-detail a.important_button i {
    display: table-cell;
    vertical-align: middle;
}
#front-blog-detail a.important_button:hover, #front-blog-detail a.important_button:focus {
    background-color: #609DE6;
    cursor: pointer;
}
#front-blog-detail .article__body blockquote {
    border-left: 6px solid #4a90e2;
    border-color: #4a90e2;
    background-color: #F6F6F6;
    display: block;
    padding: 10px 20px;
    text-align: left;
    font-size: 1.2em;
    margin-left: 8%;
}

/* BLOG TAGS */
.mytag {
    display: inline-block;
    padding: 1px 7px;
    color: #666666;
    border: solid 1px #666666;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size:0.9em;
}
.mytag:hover {
    text-decoration: none;
    color: #545454;
    border-color: #545454;
}
.fe_mytag {
    display: inline-block;
    padding: 0 3px;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.67em;
    font-weight: bolder;
    line-height: 1.9em;
}


/* NEWSLETTER */
#newsletter .ajax-box .ok {
    color: green;
}
#newsletter .ajax-box .nook {
    color: red;
}
#newsletter .submit {
    color: #fff;
}
#newsletter #nl_checkbox {
    height: auto;
    width: auto;
    -webkit-appearance: checkbox;
    margin-left: 7px;
    margin-top:8px;
    position: absolute;
}
#newsletter {
    flex-wrap: wrap;
}
#newsletter .item {
    width: 100%
}

/* COMMON */
.form-group.error {
	color: red;
}

.box-tags a{
    margin-bottom: 5px;
}

.menu-horizontal > li > a, .menu-horizontal > li > span, .menu-vertical a{
    font-weight: 600 !important;
    color: black !important;
    opacity: .7 !important;
    font-size: 14px !important;
}

/* Media Queries
*********************************************/
/* Very Large desktop */
@media (min-width: 1900px) {
}
/* Large desktops and laptops */
@media (min-width: 1200px) {
    #front-blog-detail .article__body, #front-blog-detail .article__body p, #front-blog-detail .article__body ul
    {
        font-size:19px;
        color: #000c;
    }
}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* Portrait tablets and small desktops */
@media (min-width: 769px) and (max-width: 991px) {
}
/* Landscape phones and portrait tablets */
@media (max-width: 768px) {
}
/* Portrait phones and smaller */
@media (max-width: 480px) {

}